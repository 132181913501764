import { template as template_8dabd054fe5e46b59a2e3e973c1d76e4 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8dabd054fe5e46b59a2e3e973c1d76e4(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
