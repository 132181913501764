import { template as template_54cc5cc95d6a4cf9b243333c46d79ade } from "@ember/template-compiler";
const FKText = template_54cc5cc95d6a4cf9b243333c46d79ade(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
