import { template as template_2ed1d5a874f24aca9c2329a15c87ee22 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_2ed1d5a874f24aca9c2329a15c87ee22(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
