import { template as template_fb44f14c5672402f8b99418c1a2c1b89 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fb44f14c5672402f8b99418c1a2c1b89(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
