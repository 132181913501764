import { template as template_336b49949c9e44fd90793f1d3ad5d982 } from "@ember/template-compiler";
const WelcomeHeader = template_336b49949c9e44fd90793f1d3ad5d982(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
