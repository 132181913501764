import { template as template_83f2917d7d3c4f15a8a18fb47d47cbfc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_83f2917d7d3c4f15a8a18fb47d47cbfc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
